/* @import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"); */

@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap");
body {
  margin: 0;
  font-family: "Manrope", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow: hidden;
}

.ant-form-item .ant-form-item-label > label {
  font-weight: 500;
}

/* WebKit-based browsers */
.sider-scrollbar {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #bdbdbd #f0f0f0; /* For Firefox */
}

.sider-scrollbar::-webkit-scrollbar {
  width: 8px; /* Scrollbar width */
}

.sider-scrollbar::-webkit-scrollbar-track {
  background: #f0f0f0; /* Scrollbar track */
}

.sider-scrollbar::-webkit-scrollbar-thumb {
  background-color: #bdbdbd; /* Scrollbar handle (thumb) */
  border-radius: 10px;
  border: 2px solid #f0f0f0; /* Padding around the thumb */
}

.sider-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #999999; /* Thumb color on hover */
}

.total-row td {
  background-color: #f0f0f0 !important;
  font-weight: 600 !important;
}

.ant-table-cell {
  padding: 8px 16px !important;
}

.subtotal-row td {
  background-color: #f8f8f8 !important;
  font-style: italic;
}

.total-row td {
  background-color: #f0f0f0 !important;
  font-weight: 600 !important;
}

.ant-table-cell {
  padding: 8px 16px !important;
}

.ql-editor {
  min-height: 200px;
}
